import React, { useEffect, useState } from 'react';
import axios from 'axios';
import newLogo from '../../components/Assets/Images/full-logo.png';
import './FullReport.css';
import { getConfig } from '../../services/configService';
import { useHref, useNavigate } from 'react-router-dom';

interface DealData {
    account_id: number;
    auto_renew_months: number;
    company_name: string | null;
    completion_status: number;
    contract_id: string | null;
    contract_name: string;
    contractken_id: string | null;
    created_at: string;
    created_by_id: number;
    deal_id: string | null;
    description: string;
    effective_date: string;
    estimated_value: number;
    estimated_value_currency: string;
    expiration_date: string;
    model_name: string;
    model_type_id: number;
    final_draft_score: number;
    name_of_the_other_party: string;
    dealAnalysis: DealAnalysisSummary[];
}

interface DealAnalysisSummary {
    term_source_id: number;
    term: string;
    question: string;
    weight: number;
    description: string;
    final_draft_answer_source_id: number;
    final_draft_answer: string;
    final_draft_weight: number;
    final_draft_is_model: number; // Ensure this is a number
    notes: string | null;
    model_answer: string;
}

export const FullReport = () => {
    const [showNonModelColumns, setShowNonModelColumns] = useState<boolean>(true);
    const [apiUrl, setApiUrl] = useState<string>("");
    const [reportData, setReportData] = useState<DealData | null>(null);
    const [error, setError] = useState<string | null>(null);

    //const dealId = 2; // Temporary hard-coded value for dealId

    const formatDate = (dateString: string) => {
        const date = new Date(dateString);
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${month}/${day}/${year}`;
    };

    useEffect(() => {
        const loadConfig = async () => {
            try {
                const config = await getConfig();
                setApiUrl(config.apiUrl);
            } catch (error) {
                console.error("Error loading config:", error);
            }
        };

        loadConfig();
    }, []);

    useEffect(() => {
        let userDetailID = localStorage.getItem('userDetailID');
        const fetchData = async (userId: any) => {
            try {
                if (userId == null) {
                    setError("Sorry. Full report is not available. Either you have downloaded the report already or Payment is failed for the full report !!");
                }

                const responseDealsummary = await axios.get<any>(`${apiUrl}/MarkUp/GetFullReportData?userId=` + userId);
                if (responseDealsummary) {
                    const data = responseDealsummary.data?.dealDetail;
                    if (data == null) {
                        setError("Sorry. Full report is not available. Either you have downloaded the report already or Payment is failed for the full report !!");
                        setReportData(null);
                    }
                    else {
                        setError(null);
                        setReportData(data || null);
                    }                   
                    
                } else {
                    setError("Sorry. Full report is not available. Either you have downloaded the report already or Payment is failed for the full report !!");
                    setReportData(null);
                }
            } catch (error) {
                setError("Oops! Ther is some problem to load the full report. Please contact administrator.");
                console.error('Error fetching data:', error);
            }
        };

        fetchData(userDetailID);
    }, [apiUrl]);

    const handleExport = async () => {
        try {
            let userDetailID = localStorage.getItem('userDetailID');
            const response = await axios.get<any>(`${apiUrl}/MarkUp/ExportSummaryReport?userId=` + userDetailID);
            console.log("response.data.response:", response.data.exportFileName);
            console.log("response.data:", response.data.fileData);
            const { exportFileName, fileData } = response.data;
            console.log("fileName:", exportFileName);
            if (fileData && exportFileName) {
                const binaryData = atob(fileData);
                const blob = new Blob([binaryData], { type: 'text/csv' });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', exportFileName);
                document.body.appendChild(link);
                link.click();
                window.URL.revokeObjectURL(url);

                // Now call the ClearResponse API
                try {
                    await axios.post(`${apiUrl}/MarkUp/ClearResponse?userId=` + userDetailID);
                    console.log('Responses cleared successfully.');

                    window.location.reload();
                } catch (clearError) {
                    console.error('Error clearing responses:', clearError);
                }
            } else {
                console.error('File download failed:', response.data);
            }
            localStorage.removeItem('operationId');
            localStorage.removeItem('userDetailID');
            localStorage.removeItem('dataModel');
            localStorage.removeItem('dealId');
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const toggleTermType = (type: 'model' | 'non-model') => {
        setShowNonModelColumns(type === 'non-model');
    };

    const navigate = useNavigate();
    const handleRedirect = () => {
        navigate('/');
    };

    const nonModelTerms = reportData?.dealAnalysis?.filter(term => term.final_draft_is_model === 0) || [];
    const modelTerms = reportData?.dealAnalysis?.filter(term => term.final_draft_is_model === 1) || [];

    return (
        <section className="wrapper-inner">
            <header>
                <div className="container">
                    <div className="logo" onClick={handleRedirect}><img src={newLogo} alt="" /></div>
                </div>
            </header>
            <section className="main-content">
                <div className="container">
                    {
                        error ?
                            (
                                <>
                                    <h1 className="ubuntu-bold">NDA Analyzer Full Report Error</h1>
                                    <div className="content-block">                                       
                                        <p>Full report Unavailable</p>
                                        <p>{error}</p>
                                    </div>
                                </>
                            ) :
                            (
                                <>
                       
                                <h1 className="light">
                                    {reportData?.contract_name} - {reportData?.company_name} - {reportData?.name_of_the_other_party} &gt; Summary
                                    <button type="submit" className="btn btn-primary" onClick={handleExport}>Export</button>
                                </h1>

                                <p><strong>Model:</strong> {reportData?.model_name}</p>

                                <div className="row capsule">
                                    <div className="col-sm-6 capsule-left">
                                        <p className="staticlbl"><span>Effective Date</span>{reportData ? formatDate(reportData.effective_date) : ''}</p>
                                        <p className="staticlbl"><span>Expiration Date</span>{reportData ? formatDate(reportData.expiration_date) : ''}</p>
                                    </div>
                                    <div className="col-sm-6 capsule-right">
                                        <p className="score">Current Deal Score<span>{reportData ? reportData.final_draft_score : 0}</span></p>
                                    </div>
                                </div>

                                <div className="tab-buttons">
                                    <a href="#" onClick={(e) => { e.preventDefault(); toggleTermType('non-model'); }} className={showNonModelColumns ? "tab-button active" : "tab-button"}>
                                        Non-Model Terms
                                    </a>
                                    <a href="#" onClick={(e) => { e.preventDefault(); toggleTermType('model'); }} className={!showNonModelColumns ? "tab-button active" : "tab-button"}>
                                        Model Terms
                                    </a>
                                </div>

                                <div className="grid-top">
                                    <h5>{showNonModelColumns ? 'Non-Model Terms' : 'Model Terms'}</h5>
                                    <div className="table-responsive mytable">
                                        <table className="table table-borderless">
                                            <thead>
                                                <tr>
                                                    <th style={{ width: "20%" }}>Term</th>
                                                    {showNonModelColumns && <th style={{ width: "18%" }}>Deal Answer</th>}
                                                    {!showNonModelColumns && <th style={{ width: "18%" }}>Model Answer</th>}
                                                    <th style={{ width: "15%" }}>Rating</th>
                                                    <th style={{ width: "10%", textAlign: "center" }}><input className="form-check-input" type="checkbox" /></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {(showNonModelColumns ? nonModelTerms : modelTerms).map((summary, index) => (
                                                    <React.Fragment key={index}>
                                                        <tr>
                                                            <td>{summary.term}</td>
                                                            {showNonModelColumns && (
                                                                <td className={summary.final_draft_is_model === 0 ? 'yes' : 'no'}>
                                                                    <div>{summary.final_draft_answer || ''}</div>
                                                                </td>
                                                            )}
                                                            {!showNonModelColumns && (
                                                                <td className={summary.final_draft_is_model === 1 ? 'yes' : 'no'}>
                                                                    <div>{summary.model_answer || ''}</div>
                                                                </td>
                                                            )}
                                                            <td style={{ textAlign: "center" }}>
                                                                <p className="progressbar">
                                                                    <span style={{ width: `${summary.final_draft_weight || 0}%` }}></span>
                                                                </p>
                                                                {summary.final_draft_weight || 0}% of 1 deal
                                                            </td>
                                                            <td style={{ width: "10%", textAlign: "center" }}>
                                                                <input className="form-check-input" type="checkbox" id={`flexCheckDefault${index}`} />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={showNonModelColumns ? 5 : 5}>
                                                                <div><strong>Note:</strong> {summary.notes || ''}</div> {/* Display note title and content */}
                                                            </td>
                                                        </tr>
                                                    </React.Fragment>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    </div>

                                </>
                            )
                    }

                </div>
            </section>
        </section>
    );
};
