import './Report.css';
//import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, StripeElementsOptions } from '@stripe/stripe-js';
import CheckoutForm from '../Stripe/CheckoutForm';
import ReactDOM from 'react-dom';
import { useHref } from 'react-router-dom';

import {
    PaymentElement,
    Elements,
    useStripe,
    useElements,
} from '@stripe/react-stripe-js';

import React, { useState } from 'react';


const stripePromise = loadStripe('pk_test_51OuU8vSEp04rMV5kgy9a9gUuEE1Ii0dlxacxjNIxbI2TX6daIX3e7xk09YZCdkibEEGZmVcxUskLQmbkbzanvPLz00k1Bzz3hK');

export const Report = () => {

    const [error, setError] = useState<string | undefined>(undefined);

    const options: StripeElementsOptions = {
        mode: 'payment',
        amount: 1,
        currency: 'usd',
        // Fully customizable with appearance API.
        appearance: {},
    };

    return (
        <Elements stripe={stripePromise} options={options}>
            <CheckoutForm />
        </Elements>
    );
}

