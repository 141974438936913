// HalfReportPage.tsx
import React from 'react';
import Layout from '../components/Layout/Layout';
import { HalfReport } from '../components/HalfReport/HalfReport';

const HalfReportPage: React.FC = () => {

    return (
        <HalfReport />
    );
};

export default HalfReportPage;
