// IntroPage.tsx
import React from 'react';
import Layout from '../components/Layout/Layout';
import { Report } from '../components/Report/Report';

const ReportPage: React.FC = () => {
    return (
        <Layout>
            <Report />
        </Layout>
    );
};

export default ReportPage;
