// IntroPage.tsx
import React from 'react';
import Layout from  '../components/Layout/Layout';
import { Analyzer } from '../components/Analyzer/Analyzer';

const AnalyzerPage: React.FC = () => {
    return (
        <Layout>
            <Analyzer />
        </Layout>
    );
};

export default AnalyzerPage;
