// IntroPage.tsx
import React from 'react';
import Layout from '../components/Layout/Layout';
import { Contract } from '../components/Contract/Contract';

const ContractPage: React.FC = () => {
    
    return (
        <Contract />
    );
};

export default ContractPage;
