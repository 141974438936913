import React from 'react';
import AnalyzerPage from './pages/AnalyzerPage';
import ContractPage from './pages/ContractPage';
import ReportPage from './pages/ReportPage';
import  IntroPage from './pages/IntroPage';
import './components/Assets/Css/style.css'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HalfReportPage from './pages/HalfReportPage';
import FullReportPage from './pages/FullReportPage';

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<IntroPage />} />
                <Route path="/Analyzer" element={<AnalyzerPage />} />
                <Route path="/Contract" element={<ContractPage />} />
                <Route path="/HalfReport" element={<HalfReportPage />} />
                <Route path="/Report" element={<ReportPage />} />
                <Route path="/FullReport" element={<FullReportPage />} />
            </Routes>
        </Router>
    );        
}
export default App;
