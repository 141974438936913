// Layout.tsx
import React, { ReactNode } from 'react';
import logo from '../Assets/Images/logo.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Assets/Css/style.css'
// eslint-disable-next-line
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import WebSocket from "../../components/SocketClient";

type LayoutProps = {
    children: ReactNode;
};

const Layout: React.FC<LayoutProps> = ({ children }) => {
    return (       
        <section className="wrapper-outer">
            <div className="container">
                <div className="centertile">
                    <div className="logoblock"><img src={logo} alt="" /></div>
                    <div className="contentblock">
                        {children}
                        <WebSocket />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Layout;
