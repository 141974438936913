import { useEffect, useState, useRef } from "react";
import { WebSocketService } from "./WebSocketService";
import { AxiosResponse } from 'axios';
import axios from 'axios';
import { useHref } from 'react-router-dom';
import LoadingWindow from "../LoadingWindow/LoadingWindow"; // Assuming you have a LoadingWindow component
// import { getConfig } from "@services/configService";
import { getConfig } from '../../services/configService';


export const WebSocket = () => {
    // Redux Hooks
    const [customMessage, setCustomMessage] = useState(""); // State to store the custom message
    const [apiUrl, setApiUrl] = useState<string>("");
    const [webSocketUrl, setwebSocketURLUrl] = useState<string>("wss://crx.kognova.work/tasks?token=4ylwP6a07Jw4bOMEmYNd_p0Uokp7ChWumbWqsDpBnu8");
    const href = useHref({ pathname: '/HalfReport' });
    const apiUrlRef = useRef<string | null>(null);

    useEffect(() => {

    }, []);   

    useEffect(() => {
        const loadConfig = async () => {
            try {
              const config = await getConfig();
              console.log(config)
              setApiUrl(config.apiUrl);
              apiUrlRef.current = config.apiUrl;
              setwebSocketURLUrl(config.webSocketUrl)
            } catch (error) {
              console.error("Error loading config:", error);
            }
          };
      
        loadConfig(); 
        const webSocketURL = `${webSocketUrl}`;
        const webSocketService = WebSocketService.getInstance();
        webSocketService.createConnection(`${webSocketUrl}`, onMessageReceived);
        return () => {
            webSocketService.disconnect();
        };
    }, [navigator.onLine]);

    const onMessageReceived = async (message: any) => {        
        let parsedMessage;
        parsedMessage = JSON.parse(message);
        const logData = JSON.parse(message);

            const operationIdFromStorage = localStorage.getItem('operationId');

        const operationId = logData.operation_id;
        console.log('operationId:', operationId);
        console.log('apiUrl from ref:', `${apiUrl}`, '&', `${apiUrlRef.current}`);
        // Checking for the Different Cases
        if (parsedMessage.event === "task_update") {            
            if (operationIdFromStorage === operationId ) {
                localStorage.setItem("isProcessing", "true");
                setCustomMessage(parsedMessage.message);
            }
        } else if (parsedMessage.event === "task_completed") {            
            if (operationIdFromStorage === operationId) {
                localStorage.setItem("isProcessing", "true");
                setCustomMessage(parsedMessage.message);
                if(logData.task_outcome)
                {
                    console.log('Call API');
                    try {
                        const task_outcome = logData.task_outcome;                    

                        const fileName = Object.keys(task_outcome)[0];

                    const taskOutcomeData = task_outcome[fileName];
                    
                    let userDetailID = localStorage.getItem('userDetailID');
                        console.log('taskOutcomeData = ' + taskOutcomeData);
                        console.log('userDetailID = ' + userDetailID);
                    const dataModel = {
                        NDATxt: JSON.stringify(taskOutcomeData),                        
                        UserId: userDetailID,
                        OperationId: operationId                        
                    } 
                    console.log('dataModel:', JSON.stringify(dataModel));
                    localStorage.setItem('dataModel', JSON.stringify(dataModel));
                    let dealId: any;
                    var userConfig = {
                        method: 'post',
                        url: `${apiUrlRef.current}/MarkUp/TaskCompleted`,
                        headers: {                          
                            'Content-Type': 'application/json'
                        },
                        data: JSON.stringify(dataModel)
                    };
                    const userResponse = await axios.request(userConfig)
                        .then((response: AxiosResponse<any>) => {
                            localStorage.removeItem("isProcessing");
                            setCustomMessage("");
                            if (response.data.isSuccess) {
                                dealId = response.data.response.id;
                                localStorage.setItem('dealId', dealId);
                                window.location.href = href;
                            }
                            else {
                                alert(response.data.message);
                                window.location.reload();
                            }
                        })
                        .catch((error: AxiosResponse<any>) => {
                            console.log(error);
                            localStorage.removeItem("isProcessing");
                            setCustomMessage("");
                        });

                    } catch (error) {
                        console.error('Error:', error);
                        localStorage.removeItem("isProcessing");
                        setCustomMessage("");
                    }
                }
            }
            else {

            }
        } else if (parsedMessage.event === "task_failed") {

        }
        else if (parsedMessage.event === "task_retried ") {

        }
        else {
            return;
        }
    };
    const isProcessing = localStorage.getItem("isProcessing") === "true";
    return (
        <div>        
          {isProcessing && <LoadingWindow message={customMessage} />}
        </div>
      );
};

export default WebSocket;