
import configJson from '../config/config.json';


export interface AppConfig {
    apiUrl: string;
    apiKey: string;
    webSocketUrl : string;
    stripePaymentUrl : string;
    maxItemsPerPage: number;
  }
  
  export const getConfig = async (): Promise<AppConfig> => {
    try {      
      return configJson as AppConfig;
    } catch (error) {
      console.error("Error loading config:", error);
      throw error;
    }
  };
  