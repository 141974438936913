// IntroPage.tsx
import React from 'react';
import Layout from '../components/Layout/Layout';
import { Intro } from '../components/Intro/Intro';

const IntroPage: React.FC = () => {
    return (
        <Layout>
            <Intro />
        </Layout>
    );
};

export default IntroPage;
