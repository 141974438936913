// FullReportPage.tsx
import React from 'react';
import Layout from '../components/Layout/Layout';
import { FullReport } from '../components/FullReport/FullReport';

const FullReportPage: React.FC = () => {

    return (
        <FullReport />
    );
};

export default FullReportPage;
