import { useHref, useNavigate } from 'react-router-dom';
import newLogo from '../../components/Assets/Images/full-logo.png';
import './HalfReport.css';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { getConfig } from '../../services/configService';

interface DealData {
    accountId: number;
    autoRenewMonths: number;
    company_name: string | null;
    completionStatus: number;
    contractId: string | null;
    contract_name: string;
    contractkenId: string | null;
    createdAt: string;
    createdById: number;
    dealId: string | null;
    description: string;
    effective_date: string;
    estimatedValue: number;
    estimatedValueCurrency: string;
    expiration_date: string;
    model_name: string;
    modelTypeId: number;
    final_draft_score: number;
    name_of_the_other_party: string;
    dealAnalysis: DealAnalysisSummary[];
}
interface DealAnalysisSummary {
    term_source_id: number;
    term: string;
    question: string;
    weight: number;
    description: string;
    final_draft_answer_source_id: number;
    final_draft_answer: string;
    final_draft_weight: number;
    final_draft_is_model: number; // Ensure this is a number
    notes: string | null;
    model_answer: string;
}


export const HalfReport = () => {
    const href = useHref({ pathname: '/FullReport' });
    const [apiUrl, setApiUrl] = useState<string>("");
    const [dealId, setDealId] = useState<string>("");
    const [stripeUrl, setstripeUrl] = useState<string>("");
    const [reportData, setReportData] = useState<DealData | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const loadConfig = async () => {
            try {
                const config = await getConfig();
                setApiUrl(config.apiUrl);
                setstripeUrl(config.stripePaymentUrl);
            } catch (error) {
                console.error("Error loading config:", error);
                setError("Error loading configuration.");
                setLoading(false);
            }
        };

        loadConfig();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (!apiUrl) return;
                console.log('API Url:', apiUrl);
                let userDetailID = localStorage.getItem('userDetailID');
                //const response = await axios.get<{ response: DealData[] }>(`${apiUrl}/MarkUp/GetStoredResponses`);
                const response = await axios.get<any>(`${apiUrl}/MarkUp/GetHalfReportData?userId=` + userDetailID);
                console.log('response:', response.data);
                var reportData = response.data?.dealDetail;
                if (reportData) {

                    const terms = reportData?.dealAnalysis.length;
                    if (terms > 0) {
                        const data = response.data?.dealDetail;
                        setDealId(response.data?.dealDetail?.id);
                        setReportData(data || null);
                        setError(null);
                        console.log('Extracted Data:', data);
                        console.log('data?.deal_id:', data?.id);
                    }
                    else {
                        setReportData(null);
                        setError("No data available.");
                    }
                } else {
                    setReportData(null);
                    setError("No data available.");
                }
            } catch (error) {
                console.error('Error fetching data:', error);
                setError("Error fetching data.");
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [apiUrl]);

    const formatDate = (dateString: string) => {
        const date = new Date(dateString);
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${month}/${day}/${year}`;
    };

    const handleClick = () => {

        let operationId = localStorage.getItem('operationId');
        let userDetailId = localStorage.getItem('userDetailID');
        const customParams = {
            userId: userDetailId?.toString() ?? '',
            operationId: operationId?.toString() ?? '',
            dealId: dealId?.toString() ?? ''
        };
        const queryString = new URLSearchParams(customParams).toString();
        const fullUrl = `${stripeUrl}?${queryString}`;
        //navigate(fullUrl);
        window.location.href = fullUrl;
        //const newTab = window.open(`${stripeUrl}`, '_blank');
        //if (newTab) {
        //    window.close();
        //} else {
        //    alert('Unable to open the new tab. Please try again.');
        //}
    };
    const navigate = useNavigate();
    const handleRedirect = () => {
        navigate('/');
    };

    if (loading) {
        return <p>Loading...</p>;
    }

    return (
        <section className="wrapper-inner">
            <header>
                <div className="container">
                    <div className="logo" onClick={handleRedirect}><img src={newLogo} alt="" /></div>
                </div>
            </header>
            <section className="main-content">
                <div className="container">
                    {
                        error ?
                            (
                                <>
                                    <h1 className="ubuntu-bold">NDA Analyzer Document Error</h1>
                                    <div className="content-block">                                        
                                        <p>Invalid contract document. Please upload valid contract document.</p>
                                        <p>{error}</p>
                                    </div>
                                </>
                            ) :
                            (


                                <>
                                    <h1 className="light">
                                        {reportData?.contract_name} &nbsp;-&nbsp; {reportData?.company_name} &nbsp;-&nbsp; {reportData?.name_of_the_other_party}
                                    </h1>
                                    <p><strong>Model:</strong> {reportData?.model_name}</p>
                                    <div className="row capsule">
                                        <div className="col-sm-6 capsule-left">
                                            <p className="staticlbl"><span>Effective Date</span>{reportData ? formatDate(reportData.effective_date) : ''}</p>
                                            <p className="staticlbl"><span>Expiration Date</span>{reportData ? formatDate(reportData.expiration_date) : ''}</p>
                                        </div>
                                        <div className="col-sm-6 capsule-right">
                                            <p className="score">Current Deal Score<span>{reportData?.final_draft_score}</span></p>
                                        </div>
                                    </div>
                                    <div className="btn-block">
                                        <button type="button" className="btn btn-primary btn-big" onClick={handleClick}>Unlock Full Report $ XYZ</button>
                                    </div>
                                </>


                            )
                    }
                </div>
            </section>
        </section>
    );
};
