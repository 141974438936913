import React, { useState, useEffect } from "react";
import "./LoadingWindow.css"; // Import CSS file for styling
interface LoadingWindowProps {
    message?: string; // Optional message prop with a default value of undefined
  }

const LoadingWindow: React.FC<LoadingWindowProps> = ({ message }) => {

  useEffect(() => {
  }, []);

  // Render nothing if loading window is not visible
  if (!message && message?.length == 0) {
    return null;
  }
  const displayedMessage = message || "Loading..."; // Use provided message or default

  return (
    <div className="loading-wrapper">
      <div className="loading-window">
        <h3>Please Wait .....</h3>
        {displayedMessage && <p>{displayedMessage}</p>}
      </div>
    </div>
  );
};

export default LoadingWindow;
