import './CheckoutForm.css';
import { useState } from 'react';
import { useStripe, useElements, PaymentElement, CardElement } from '@stripe/react-stripe-js';
import { useHref } from 'react-router-dom';
const CheckoutForm = () => {
    const [error, setError] = useState<string | undefined>(undefined);
    const stripe = useStripe();
    const elements = useElements();

    const href = useHref({ pathname: '/FullReport' });

    //const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        //event.preventDefault();

        //if (!stripe || !elements) {
        //    return; // Stripe.js has not yet loaded
        //}

        //const cardElement = elements.getElement(CardElement);
        //if (!cardElement) {
        //    setError('Card element not found');
        //    return;
        //}
        //const clientSecret = 'sk_test_51OuU8vSEp04rMV5k81Lho9q95EsUyiGUzGcgIaRcuDYTQVM25t2czrKofxii3KOcrtAdGamhlnwVj7OeKp1zrCoF00aJJyIjaw';
        //const { error } = await stripe.confirmCardPayment(clientSecret, {
        //    payment_method: {
        //        card: cardElement,
        //    }
        //});

        //if (error) {
        //    setError(error.message);
        //} else {
        //    // Payment successful, you can handle success here
        //}
    //};
    const handle12Click = async () => {
        try {
            window.location.href = href;
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <form>
            <PaymentElement />
            <div className="btnblock m-4 mt-5"><button type="button" className="btn btn-primary btn-big" onClick={handle12Click}>Submit</button></div>
        </form>
    );
};

export default CheckoutForm;