import { useHref } from 'react-router-dom';
import newLogo from '../../components/Assets/Images/full-logo.png';
import './Contract.css';

export const Contract = () => {

        const href = useHref({ pathname: '/HalfReport' });

        const handleClick = async () => {
            try {
                window.location.href = href;
            } catch (error) {
                console.error('Error:', error);
            }
        };
    return (
        <section className="wrapper-inner">
            <header>
                <div className="container">
                    <div className="logo"><img src={newLogo} alt="" /></div>
                </div>
            </header>
            <section className="main-content">
                <div className="container">
                    <h1>NDA Analyzer</h1>
                    <div className="content-block">
                        <div className="form-floating mb-4">
                            <input type="text" className="form-control" id="email" placeholder="Enter email" name="email" />
                            <label htmlFor="email">Model</label>
                        </div>

                        <div className="form-floating mt-4 mb-4">
                            <input type="text" className="form-control" id="pwd" placeholder="Enter password" name="name" />
                            <label htmlFor="name">Contract Name</label>
                        </div>

                        <div className="form-floating mb-4">
                            <input type="text" className="form-control" id="email" placeholder="Enter email" name="email" />
                            <label htmlFor="email">My Company (Subsidiary)</label>
                        </div>

                        <div className="form-floating mt-4 mb-5">
                            <input type="text" className="form-control" id="pwd" placeholder="Enter password" name="name" />
                            <label htmlFor="name">Name of the Other Party</label>
                        </div>

                        <div className="form-floating mt-4 mb-5">
                            <textarea className="form-control" placeholder="Leave a comment here" id="floatingTextarea"></textarea>
                            <label htmlFor="name">Overview</label>
                        </div>

                        <button type="submit" className="btn btn-primary" onClick={handleClick}>Submit</button>
                        <button type="submit" className="btn btn-secondary">Cancel</button>
                    </div>
                </div>
            </section>
        </section>     
     )
}
