import React from 'react'
import { useHref } from 'react-router-dom';
import './Intro.css';


export const Intro = () => {

    const href = useHref({ pathname: '/Analyzer' });

    const handleClick = async () => {
        try {
             window.location.href = href;
        } catch (error) {
            console.error('Error:', error);
        }
    };

  return (
      <div>
          <h4 className="ubuntu-bold">Welcome to ContractsRx - Your AI-Powered Contract Review Solution</h4>
          <p>Combining the power of AI with contract review models created by experienced contract professionals, allowing you to easily upload your contracts and have them analyzed. The ContractsRx system identifies potential issues and generates a risk score for each agreement. The CRx Risk Score Report displays potential risks to help you make informed decisions and prioritize your contract review efforts.</p>

          <p><b>Key Features:</b></p>
          <ul className="common-listing">
              <li>Rapid Contract Analysis: Our AI engine swiftly reviews your contracts, providing you with actionable insights in a matter of minutes.</li>
              <li>Comprehensive Risk Assessment: Our advanced algorithms evaluate your contracts based on a wide range of parameters, identifying potential risks and areas of concern.</li>
              <li>User-Friendly Interface: Our intuitive platform makes it easy to upload, manage, and review your contracts, even for non-technical users.</li>
              <li>Secure and Confidential: We prioritize the security and confidentiality of your data, ensuring that your contracts are protected at all times.</li>
          </ul>

          <p><b>How It Works:</b></p>
          <ul className="common-listing">
              <li>Upload Your Contract: Simply upload your contract to our secure platform.</li>
              <li><b>AI Analysis:</b> Our AI engine analyzes your contract based on our extensive model of questions and answers.</li>
              <li>Risk Score Generation: The system generates a comprehensive risk score for your contract, highlighting potential issues and concerns.</li>
          </ul>

          <p>Whether you're an individual or small business owner, ContractsRx can help you streamline your contract review process, identify potential risks, and save valuable time.</p>

          <p>ContractsRx is an AI-powered tool designed to assist users in reviewing and analyzing contracts. However, it is important to note that ContractsRx does not provide legal advice. The information provided by our platform is for general informational purposes only and should not be considered a substitute for counsel based on your specific situation, as may be provided by a legal professional. Users are advised to consult with qualified legal professionals for specific legal advice tailored to their unique situations and jurisdictions.</p>

          <div className="btnblock m-4 mt-5">
              <button type="button" onClick={handleClick} className="btn btn-primary btn-big">START HERE</button>
          </div>
      </div>
     
  )
}
