import Stomp from "stompjs";
import SockJS from "sockjs-client";

export class WebSocketService {
    private static instance: WebSocketService | null = null;
    private stompClient: Stomp.Client | null = null;
    private reconnectInterval: NodeJS.Timeout | null = null;
    private isConnected: boolean = false;

    public static getInstance(): WebSocketService {
        if (!WebSocketService.instance) {
            WebSocketService.instance = new WebSocketService();
        }
        return WebSocketService.instance;
    }


    public createConnection(url: string, onMessageReceived: (message: any) => void): void {
        if ((this.stompClient && this.isConnected) || !navigator.onLine) {
            return;
        }
        const socket = new WebSocket(url);
        this.stompClient = Stomp.over(socket);
        console.log('WebSocket Connected')     
        
        socket.onmessage = (message) => {
            console.log(message)
            // onMessageReceived(JSON.stringify(message))
                        
            onMessageReceived(message.data);
          
        }
    }

    private setupReconnectInterval(url: string, onMessageReceived: (message: any) => void): void {
        this.clearReconnectInterval();
        this.reconnectInterval = setInterval(() => {
            this.createConnection(url, onMessageReceived);
        }, 10000);
    }

    private clearReconnectInterval(): void {
        if (this.reconnectInterval) {
            clearInterval(this.reconnectInterval);
            this.reconnectInterval = null;
        }
    }

    public disconnect(): void {
        if (this.stompClient && this.isConnected) {
            this.stompClient.disconnect(() => {
                console.log("Disconnected from WebSocket");
                this.isConnected = false;
                this.stompClient = null;
            });
        }
        this.clearReconnectInterval();
    }
}
